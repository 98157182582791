import {
  CompanyPage,
  SingleEventPage,
  InfoHub,
  Chat,
  Calendar,
  Availability,
  SocialBoard,
  EditPage,
  SingleTeamPage,
  TeamsPage,
  Profiles,
  Home,
  CompanySetting,
  UpDashboard,
  ProfilePage,
  Templates,
  Courses,
  CreateCourse,
  Quiz,
  StartCourse,
  CoursePreview
} from 'pages';
import Info from 'components/organisms/Info/Info';
import SinglePostPage from 'pages/SinglePostPage';
import { withAdminAccess, withProfileAccess } from 'HOCs';
import Explore from 'components/organisms/Explore/Explore';
import StartGuide from 'components/organisms/StartGuide/StartGuide';
import { ROUTES_PATHS } from 'constants';
import NewCoursePage from 'pages/CreateCourse';
import QuizPreview from 'pages/QuizPreviewPage';

// ==================== HOCs ==========================
const ProtectedEdit = withProfileAccess(EditPage);
const ProtectedSettings = withAdminAccess(CompanySetting);
// ====================================================

const routes = [
  // NOTE: the names of the routes sometimes are used, so avoid changing it .e.g All Profiles
  { path: ROUTES_PATHS.home().value, name: 'Home', exact: true, element: Home, private: true },
  {
    path: ROUTES_PATHS.guides().value,
    name: 'Guides',
    exact: true,
    element: StartGuide,
    private: true
  },
  {
    path: ROUTES_PATHS.allProfiles().value,
    name: 'All Profiles',
    exact: true,
    element: Profiles,
    private: true,
    isSharable: true,
    acceptedRegex: /\/profiles\?slug=shared-(private|public)-\w+/
  },
  {
    path: ROUTES_PATHS.profile().value,
    name: 'Profile',
    element: ProfilePage,
    private: true,
    isSharable: true
  },
  {
    path: ROUTES_PATHS.userProfile().value,
    name: 'User Profile',
    element: ProfilePage,
    private: true,
    isSharable: true,
    acceptedRegex: /\/profile\/.*slug=\w+-shared-(private|public)-\w+/
  },
  { path: ROUTES_PATHS.allTeams().value, name: 'All Teams', element: TeamsPage, private: true },
  {
    path: ROUTES_PATHS.teamProfile().value,
    name: 'Team Profile',
    element: SingleTeamPage,
    private: true
  },
  { path: ROUTES_PATHS.calendar().value, name: 'Calendar', element: Calendar, private: true },
  {
    path: ROUTES_PATHS.singleEvent().value,
    name: 'Single Event',
    element: SingleEventPage,
    private: false
  },
  { path: ROUTES_PATHS.edit().value, name: 'Edit', element: ProtectedEdit, private: true },
  {
    path: ROUTES_PATHS.socialBoard().value,
    name: 'Social Board',
    element: SocialBoard,
    private: true
  },
  { path: ROUTES_PATHS.templates().value, name: 'Templates', element: Templates, private: true },
  {
    path: ROUTES_PATHS.newshubPost().value,
    name: 'Newshub Post',
    element: SinglePostPage,
    isSharable: true,
    acceptedRegex: /\/sharedPost\?slug=shared-(private|public)-\w+/
  },
  {
    path: ROUTES_PATHS.workingHours().value,
    name: 'Working Hours',
    element: Availability,
    private: true
  },
  { path: ROUTES_PATHS.chat().value, name: 'Chat', element: Chat, private: true },
  { path: ROUTES_PATHS.infoHub().value, name: 'Info Hub', element: InfoHub, private: true },
  { path: ROUTES_PATHS.info().value, name: 'Info', element: Info, private: true },
  {
    path: ROUTES_PATHS.userCompany().value,
    name: 'Company',
    element: CompanyPage,
    isGlobal: true,
    acceptedRegex: /\/company\/\w+/
  },
  { path: ROUTES_PATHS.company().value, name: 'Company', element: CompanyPage, private: false },
  {
    path: ROUTES_PATHS.companySetting().value,
    name: 'Company Setting',
    element: ProtectedSettings,
    private: true
  },
  { path: ROUTES_PATHS.explore().value, name: 'Explore', element: Explore, private: true },
  { path: ROUTES_PATHS.courses().value, name: 'Courses', element: Courses, private: true },
  {
    path: ROUTES_PATHS.myDashboard().value,
    name: 'My Dashboard',
    element: UpDashboard,
    private: true,
    isSharable: false,
    acceptedRegex: /\/dashboard(\/.*)?/
  },
  {
    path: ROUTES_PATHS.newCourse().value,
    name: 'New Course',
    element: NewCoursePage,
    private: true
  },
  {
    path: ROUTES_PATHS.quiz().value,
    name: 'Quiz',
    element: Quiz,
    private: true,
    isSharable: false,
    acceptedRegex: /\/quiz(\/.*)?/
  },
  {
    path: ROUTES_PATHS.quizPreview().value,
    name: 'Quiz Preview',
    element: QuizPreview,
    private: true
  },
  {
    path: ROUTES_PATHS.course().value,
    name: 'Course',
    element: StartCourse,
    private: true,
    isSharable: false,
    acceptedRegex: /\/course(\/.*)?/
  },
  {
    path: ROUTES_PATHS.coursePreview().value,
    name: 'Course Preview',
    element: CoursePreview,
    private: true
  }
];

export default routes;
