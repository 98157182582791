import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import styles from './CourseDropdown.module.scss';
import Arrow from 'assets/icons/arrowrotate.svg?react';
import File from 'assets/icons/document.svg?react';
import Play from 'assets/icons/video.svg?react';
import Close from 'assets/icons/closeIcon.svg?react';

const Dropdown = ({ title, lesson, contentArray, isOpen }) => {
  const [selectedContent, setSelectedContent] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePreview = (url) => {
    setSelectedContent(url);
  };

  const closePreviewPopup = () => {
    setSelectedContent(null);
  };

  useEffect(() => {
    setIsDropdownOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.top} aria-hidden="true" onClick={toggleDropdown}>
          <div className={styles.title}>
            <Arrow className={isDropdownOpen ? styles.opened : styles.closed} />
            <h2>{title}</h2>
          </div>
          <div className={styles.right}>
            <p>
              {lesson} {lesson > 1 ? 'Lessons' : 'Lesson'}
            </p>
          </div>
        </div>
        <div className={`${styles.content} ${isDropdownOpen ? styles.open : styles.closed}`}>
          <div className={styles.data}>
            {contentArray?.map((item, i) => (
              <div key={i} className={styles.item}>
                <div className={styles.left}>
                  {item?.videoUrl?.endsWith('.pdf') ? <File /> : <Play />}
                  <h3>{item?.title}</h3>
                </div>
                <div className={styles.actions}>
                  <button
                    onClick={() => handlePreview(item?.videoUrl)}
                    className={styles.previewButton}
                  >
                    Preview
                  </button>
                  <p>{item?.duration} min</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {selectedContent && (
          <div className={styles.previewPopup}>
            <button className={styles.closeButton} onClick={closePreviewPopup}>
              <Close />
            </button>
            {selectedContent.endsWith('.pdf') ? (
              <div className={styles.pdfViewer}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                  <Viewer fileUrl={selectedContent} />
                </Worker>
              </div>
            ) : (
              <video src={selectedContent} controls autoPlay className={styles.videoPlayer}></video>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Dropdown;
