import CoursePreview from 'components/organisms/CoursePreview/CoursePreview';
import React from 'react';

export default function CoursePreviewPage() {
  return (
    <div>
      <CoursePreview />
    </div>
  );
}
