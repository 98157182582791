import React, { useState } from 'react';
import NotesEditor from './Editor';
import styles from '../../../styles.module.scss';
import style from './styles.module.scss';
import { CustomDropdown } from 'components/form';
import NoteCard from './NoteCard';
import { courseNotes } from 'constants/courses';

export default function Index() {
  const [notes, setNotes] = useState([]);
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState('myNotes');

  const handleAddNote = (newNote) => {
    setNotes([newNote, ...notes]);
    setData('');
  };

  const handleFilterChange = (selectedOption) => {
    setFilter(selectedOption === 'All Notes' ? 'all' : 'myNotes');
  };

  const dropdownOptions = [
    { text: 'All Notes', label: 'All Notes' },
    { text: 'My Notes', label: 'My Notes' }
  ];

  const notesLength = filter === 'all' ? courseNotes.length : notes.length;

  return (
    <div>
      <div className={style.topWrapper}>
        <h1 className={styles.sectionTitle}>Notes ({notesLength})</h1>
        <CustomDropdown
          list={dropdownOptions}
          value={filter === 'all' ? 'All Notes' : 'My Notes'}
          onChange={handleFilterChange}
          placeholder="Select Notes"
          extraClass="notesDropdown"
        />
      </div>

      {filter === 'all' ? (
        <div className={style.notesContainer}>
          {courseNotes.map((note, index) => (
            <NoteCard
              key={index}
              title={note.title}
              note="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet mi in dui pellentesque placerat sit amet id urna. Maecenas in est consectetur leo consequat suscipit. Quisque luctus enim dui, nec facilisis est scelerisque non. Mauris mattis tortor sapien, at lobortis sed."
              name={note.name}
              position={note.position}
            />
          ))}
        </div>
      ) : (
        <>
          <NotesEditor
            placeholderText="Add a note..."
            data={data}
            setData={setData}
            onAddNote={handleAddNote}
          />
          <div className={style.notesContainer}>
            {notes.map((note, index) => (
              <div key={index} className={style.note}>
                {note.map((block, i) => (
                  <div key={i} style={{ textAlign: block.align || 'left' }}>
                    {block.children.map((leaf, j) => (
                      <span
                        key={j}
                        style={{
                          fontWeight: leaf.bold ? 'bold' : 'normal',
                          fontStyle: leaf.italic ? 'italic' : 'normal',
                          textAlign: block.align || 'left'
                        }}
                      >
                        {leaf.text}
                      </span>
                    ))}
                    {block.type === 'image' && <img src={block.url} alt="media" />}
                    {block.type === 'video' && (
                      <video controls>
                        <source src={block.url} type="video/mp4" />
                      </video>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
