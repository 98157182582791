import styles from './RelatedCourses.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Arrow from 'assets/icons/arrowSlider.svg?react';
import CourseCard from 'components/atoms/CourseCard/CourseCard';
import { coursesData } from 'constants/courses';

export default function RelatedCourses() {
  const filterContent = coursesData.map(({ date, ...rest }) => rest);

  const cardsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <div className={styles.arrow}>
        <Arrow />
      </div>
    ),
    nextArrow: (
      <div className={styles.arrow}>
        <Arrow />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  return (
    <div className={styles.container}>
      <div className={styles.relatedCont}>
        <div className={styles.courses}>
          <h2 className={styles.title}>Related Courses</h2>
          <div className={styles.slider}>
            <Slider {...cardsSlider}>
              {filterContent?.map((d, i) => (
                <CourseCard extraClass="suggestionCard" {...d} key={i} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
