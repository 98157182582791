import React from 'react';
import styles from './styles.module.scss';
import Favorite from 'assets/icons/fav.svg?react';

export default function NoteCard({ title, note, name, position }) {
  return (
    <div className={styles.noteCard}>
      <div className={styles.top}>
        <h1>{title}</h1>
        <Favorite />
      </div>
      <p className={styles.noteText}>{note}</p>
      <span className={styles.time}>5 min ago</span>
      <h2 className={styles.name}>
        {name} ({position})
      </h2>
    </div>
  );
}
