import React from 'react';
import { useSelector } from 'react-redux';
import { useFilteredCourses } from '../../hooks/useFilteredCourses';
import { usePagination } from '../../hooks/usePagination';
import NoResultsMessage from '../NoResultsMessage';
import CourseCard from 'components/atoms/CourseCardProgress/CourseCard';
import ListViewComponent from '../ListView';
import Pagination from '../Pagination';
import styles from '../../DashboardView.module.scss';


const CourseList = ({ courses }) => {
  const { course: { displayView } } = useSelector(state => state.course);
  const { filteredCourses, searchText } = useFilteredCourses(courses);
  const {
    paginatedData,
    currentPage,
    pageNumbers,
    navigateToPage,
  } = usePagination(filteredCourses, 10);

  return (
    <>
      {filteredCourses.length === 0 ? (
        <NoResultsMessage searchText={searchText} />
      ) : (
        <>
          {displayView === 'grid' && (
            <div className={styles.cardlist}>
              {paginatedData?.map((d, i) => (
                <CourseCard {...d} key={i} />
              ))}
            </div>
          )}
          {displayView === 'list' && (
            <ListViewComponent courses={paginatedData} />
          )}

          <Pagination
            pageNumbers={pageNumbers}
            navigateToPage={navigateToPage}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  );
}

export default CourseList;
