import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import CourseBanner from 'components/atoms/CourseBanner/CourseBanner';
import AboutCourse from 'components/molecules/AboutCourse/AboutCourse';
import RelatedCourses from 'components/molecules/RelatedCourses/RelatesCourses';
import CourseReviews from 'components/molecules/CourseReviews/CourseReviews';
import CourseStructure from 'components/molecules/CourseStructure/CourseStructure';
import InstructorSection from 'components/molecules/InstructorSection/InstructorSection';
export default function CoursePreview() {
  const skills = ['Tech', 'Leadership', 'Mentor'];
  return (
    <>
      <CourseBanner
        image="https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/Rectangle+75.jpg"
        title="Paint Techniques "
        subtitle="Antonio Mila"
        description="Assumenda omnis consequuntur. Qui molestiae est ut molestiae. Sit illum quia quis tenetur vel mollitia ducimus et. Neque quae repellat molestiae quos vel."
      />
      <ResponsiveWrapper>
        <AboutCourse
          video="https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708529174/9fc423c1-0fe1-4d4c-86a6-b9a9fb1a20b1_k9c5pv.mp4"
          skills={skills}
          description="In this Mental Health course you will learn what the responsibilities are from both employers and employees in relation to safety, equality and diversity, discrimination, bullying and harassment and conflict."
        />
        <CourseStructure editable={false} />
        <InstructorSection />
      </ResponsiveWrapper>
      <CourseReviews />
      <RelatedCourses />
    </>
  );
}
